import * as User from '@/utils/user'
import Axios, { AxiosResponse, AxiosPromise, AxiosError } from 'axios'
import Store from '@/store'
import { getShopId, getApiHost } from '@/utils/utils'
import router from '@/router'

// php
const phpInstace = Axios.create({
  // baseURL: getApiHost('phpInstace'),
  timeout: 10000,
  // headers: {
  //   'x-platform': 'web',
  //   'x-shop': getShopId(),
  // },
  // withCredentials: true,
})

const phpApi = function(params: any) : Promise<any> {
  // console.log('=====> phpApi')
  const final = Object.assign({
    baseURL: getApiHost('phpApi'),
    headers: {
      'x-platform': 'pc_browser',
      'x-shop': getShopId(),
      'x-device-id': Store.state.fingerprint
    }
  }, params)
  return phpInstace(final).then(res => res)
}

// 添加请求拦截器
phpInstace.interceptors.request.use((config: any) => {
  const sign = User.getSign()
  if (sign) {
    config.headers['x-member'] = encodeURI(JSON.stringify(sign))
  }
  config.params = Object.assign({}, config.params, { shop_id: getShopId() })
  return config
}, (error: any) => {
  console.warn('request error:',error)
  // 对请求错误做些什么
  return Promise.reject(error)
})

// 添加响应拦截器
phpInstace.interceptors.response.use((response: AxiosResponse<any>): any => {
  const error = response.data.error
  const data = response.data.response
  if (error) {
    console.warn('phpapi business error:',response.data)
    if(error === 'shop-closed') {
      router.replace({ name: 'closed' }).catch(error=> error)
    }
    return response.data
  } else {
    return data
  }
}, (error: AxiosError) => {
  console.warn('response error:', error)
  if (error.response!.status === 401) {
    console.log('接口401了呀');
    
    console.warn('response 401:', error.response)
    User.removeSign()
    let data: any = error.response!.data
    if (data && data.error === '10017') {
      Store.commit('SET_LOGIN_DIALOG', {
        visible: true,
        m: data.message
      })
    } else {
      User.toLogin()
    }
  }
  // 对响应错误做点什么
  return Promise.reject(error)
})

// =====================================================================================

// python
const pyInstance = Axios.create({
  // baseURL: getApiHost('pyInstance'),
  timeout: 10000,
  // headers: {
  //   'x-platform': 'web',
  //   'x-shop': getShopId()
  // }
})

const pyApi = function(params: any,data ?:any) : Promise<any> {
  // console.log('=====> pyApi')
  const final = Object.assign({
    baseURL: getApiHost('pyInstance'),
    headers: {
      'x-platform': 'pc_browser',
      'x-shop': getShopId(),
      'x-device-id': Store.state.fingerprint
    }
  }, params ,data)
  return pyInstance(final).then(res => res)
}

// 添加请求拦截器
pyInstance.interceptors.request.use((config: any) => {
  const sign = User.getSign()
  if (sign) {
    config.headers['x-member'] = encodeURI(JSON.stringify(sign))
  }
  config.params = Object.assign({}, config.params, { shop_id: getShopId() })
  return config
}, (error: AxiosError) => {
  console.warn('request error:',error)
  // 对请求错误做些什么
  return Promise.reject(error)
})

// 添加响应拦截器
pyInstance.interceptors.response.use((response: any): any => {
  const error = response.data.error
  const data = response.data.response
  if (error) {
    console.warn('pyapi business error:',response)
    if(error === 'shop-closed') {
      router.replace({ name: 'closed' }).catch(error=> error)
    }
    return response.data
  } else {
    return data
  }
}, (error: AxiosError) => {
  console.warn('response error:',error)
  if (error.response!.status === 401) {
    console.warn('response 401:', error.response)
    User.removeSign()
    let data: any = error.response!.data
    if (data && data.error === '10017') {
      Store.commit('SET_LOGIN_DIALOG', {
        visible: true,
        m: data.message
      })
    } else {
      User.toLogin('', 'replace')
    }
  }
  // 对响应错误做点什么
  return Promise.reject(error)
})

export {
  pyApi,
  phpApi,
  AxiosPromise,
  AxiosResponse
}
